<template>
  <b-container
    class="bv-example-row mb-3"
    fluid
  >
    <hr>
    <b-row>
      <b-col cols="8">
        <v-select
          v-model="year"
          :options="years"
        />
        <v-select
          v-model="month"
          :options="months"
          class="ml-2"
        />
      </b-col>
      <b-col
        cols="2"
        style="justify-content: end; display: flex"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="print"
        >
          <feather-icon
            icon="PrinterIcon"
            class="mr-50"
          />
          Listeyi Yazdır
        </b-button>
      </b-col>
    </b-row>
    <hr>
    <b-card header-tag="header">
      <template #header>
        <h4 v-show="!filtre">
          Yılı Ayı Konaklama ve Tarife Raporu
        </h4>
        <h4 v-show="filtre">
          {{ year }} Yılı {{ month }} Ayı Konaklama ve Tarife Raporu
        </h4>
      </template>
      <hr>
      <strong>Üye Konaklama</strong>
      <table class="table table-borderless mb-2 mt-1">
        <thead>
          <tr>
            <th
              v-for="(item, index) in this.$store.state.raporlar.aylikRapor
                .toplamGun == '28'
                ? 28
                : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '29'
                  ? 29
                  : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '30'
                    ? 30
                    : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '31'
                      ? 31
                      : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '32'
                        ? 32
                        : ''"
              :key="index"
              scope="col"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(item, index) in this.$store.state.raporlar.aylikRapor
                .toplamGun == '28'
                ? 28
                : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '29'
                  ? 29
                  : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '30'
                    ? 30
                    : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '31'
                      ? 31
                      : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '32'
                        ? 32
                        : ''"
              :key="index"
            >
              {{ uyeControl(item) }}
            </td>
          </tr>
        </tbody>
      </table>
      <strong>Kamu Konaklama</strong>
      <table class="table table-borderless mb-2 mt-1">
        <thead>
          <tr>
            <th
              v-for="(item, index) in this.$store.state.raporlar.aylikRapor
                .toplamGun == '28'
                ? 28
                : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '29'
                  ? 29
                  : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '30'
                    ? 30
                    : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '31'
                      ? 31
                      : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '32'
                        ? 32
                        : ''"
              :key="index"
              scope="col"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(item, index) in this.$store.state.raporlar.aylikRapor
                .toplamGun == '28'
                ? 28
                : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '29'
                  ? 29
                  : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '30'
                    ? 30
                    : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '31'
                      ? 31
                      : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '32'
                        ? 32
                        : ''"
              :key="index"
            >
              {{ kamuControl(item) }}
            </td>
          </tr>
        </tbody>
      </table>
      <strong>Sivil Konaklama</strong>
      <table class="table table-borderless mb-2 mt-1">
        <thead>
          <tr>
            <th
              v-for="(item, index) in this.$store.state.raporlar.aylikRapor
                .toplamGun == '28'
                ? 28
                : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '29'
                  ? 29
                  : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '30'
                    ? 30
                    : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '31'
                      ? 31
                      : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '32'
                        ? 32
                        : ''"
              :key="index"
              scope="col"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(item, index) in this.$store.state.raporlar.aylikRapor
                .toplamGun == '28'
                ? 28
                : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '29'
                  ? 29
                  : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '30'
                    ? 30
                    : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '31'
                      ? 31
                      : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '32'
                        ? 32
                        : ''"
              :key="index"
            >
              {{ sivilControl(item) }}
            </td>
          </tr>
        </tbody>
      </table>
      <strong style="margin-top: 20px">Toplam Konaklama</strong>
      <table class="table table-borderless">
        <thead>
          <tr>
            <th
              v-for="(item, index) in this.$store.state.raporlar.aylikRapor
                .toplamGun == '28'
                ? 28
                : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '29'
                  ? 29
                  : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '30'
                    ? 30
                    : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '31'
                      ? 31
                      : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '32'
                        ? 32
                        : ''"
              :key="index"
              scope="col"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(item, index) in this.$store.state.raporlar.aylikRapor
                .toplamGun == '28'
                ? 28
                : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '29'
                  ? 29
                  : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '30'
                    ? 30
                    : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '31'
                      ? 31
                      : '' || this.$store.state.raporlar.aylikRapor.toplamGun == '32'
                        ? 32
                        : ''"
              :key="index"
            >
              <p>
                {{ totalControl(item) }}
              </p>
              <p>%{{ dolulukControl(item) }}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <b-row class="justify-content-end">
        <div class="d-grid justify-content-end">
          <hr>
          <h4>Rapor Özeti(Konaklama Sayısı)</h4>
          <h5>
            {{ $store.state.raporlar.aylikRapor.uyeToplam }} Üye Konaklama Kaydı
            Bulundu
          </h5>
          <h5>
            {{ $store.state.raporlar.aylikRapor.kamuToplam }} Kamu Misafir
            Konaklama Kaydı Bulundu
          </h5>
          <h5>
            {{ $store.state.raporlar.aylikRapor.sivilToplam }} Sivil Misafir
            Konaklama Kaydı Bulundu
          </h5>
          <h5>
            <b>Toplam
              {{
                $store.state.raporlar.aylikRapor.uyeToplam +
                  $store.state.raporlar.aylikRapor.kamuToplam +
                  $store.state.raporlar.aylikRapor.sivilToplam
              }}
              Adet Konaklama Kaydı Bulundu
            </b>
          </h5>
          <hr>
          <h4>Rapor Özeti(Kişi Sayısı)</h4>
          <h5>
            {{ $store.state.raporlar.tekil.uye }} Üye Konaklama Kaydı Bulundu
          </h5>
          <h5>
            {{ $store.state.raporlar.tekil.kamu }} Kamu Misafir Konaklama Kaydı
            Bulundu
          </h5>
          <h5>
            {{ $store.state.raporlar.tekil.sivil }}
            Sivil Misafir Konaklama Kaydı Bulundu
          </h5>
          <h5>
            <b>Toplam
              {{ $store.state.raporlar.tekil.toplam }}
              Adet Konaklama Kaydı Bulundu
            </b>
          </h5>
          <hr>
        </div>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BFormGroup,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      years: [2019, 2020, 2021, 2022, 2023, 2024, 2025],
      months: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      year: 2023,
      month: 'Ocak',
      filtre: false,
    }
  },
  computed: {
    gunSayisi() {
      return parseInt(this.$store.state.raporlar.aylikRapor.toplamGun)
    },
  },
  watch: {
    year(newVal, oldVal) {
      this.$store.dispatch('aylikRapor', {
        year: newVal,
        month: this.month,
      })
    },
    month(newVal, oldVal) {
      this.$store.dispatch('aylikRapor', {
        year: this.year,
        month: newVal,
      })
    },
  },
  mounted() {},
  beforeDestroy() {
    this.$store.commit('clearAylikRapor')
  },
  created() {
    this.filtre = true
    const today = new Date()
    const yyyy = today.getFullYear()
    this.$store.dispatch('aylikRapor', {
      year: yyyy,
      month: 'Ocak',
    }).then((res, position = localStorage.getItem('otelSuccessReturn')) => {
      const mesaj = this.$store.getters.notificationSettings(res)
      if (mesaj) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Başarılı',
            text: mesaj,
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        }, { position })
      }
    }).catch(err => {
      const mesaj = this.$store.getters.notificationSettings(err)
      if (mesaj) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: mesaj,
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    })
  },
  methods: {
    dolulukControl(item) {
      return this.$store.state.raporlar.aylikRapor[item].dolulukOrani
    },
    totalControl(item) {
      return (
        this.$store.state.raporlar.aylikRapor[item].sivil
        + this.$store.state.raporlar.aylikRapor[item].kamu
        + this.$store.state.raporlar.aylikRapor[item].uye
      )
    },
    sivilControl(item) {
      return this.$store.state.raporlar.aylikRapor[item].sivil
    },
    kamuControl(item) {
      return this.$store.state.raporlar.aylikRapor[item].kamu
    },
    uyeControl(item) {
      return this.$store.state.raporlar.aylikRapor[item].uye
    },
    print() {
      window.print()
    },
    rezGo(rezNo) {
      this.$router.push(`/rezervasyon/${rezNo}`)
    },
  },
}
</script>

<style scoped>
.v-select {
  display: inline-block !important;
  width: 400px;
}
p {
  padding: 0 !important;
  margin: 0 !important;
}
table {
  border: 1px solid grey;
}
[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}
@media print {
  * {
    display: none;
  }
  .card .table {
    display: block;
  }
}
.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}
.myButton {
  color: white !important;
}
.myButton:hover {
  color: white !important;
}
a {
  color: #408dcc !important;
}
a:hover {
  color: #6d62e4 !important;
}
.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}
.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}
.row {
  justify-content: space-between;
}
</style>
<style>
.content-wrapper {

}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
